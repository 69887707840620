import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  deleteinvistigations,
  updateinvistigations,
} from "./investgation_operation";
import MultiSelectComp from "./MultiSelectComp";

import FirstPrintPage from "../../../../print/printTremnats/Invistigation_print";

const InvestigaitonSection = (props) => {
  let isComplete = props.state.state == "completed";

  
  
  let list = [];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  props.state.invistigation?.forEach((element) => {
    
    list.push(
      <tr key={element.id}>
        <td>{element.test.name}</td>
        <td>
          <button
            disabled={isComplete}
            className="btn btn-danger"
            onClick={(event) => {
              
              deleteinvistigations(element.id);
            }}
          >
            <i className="fas fa-trash text-white"> </i>
          </button>
        </td>
        <td>{element.result_number}</td>

        <td>
          <button
            className="btn  btn-primary"
            disabled={isComplete}
            onClick={(e) => {
              let newState;
              if (
                element.result_number == "Nil" ||
                element.result_number == "AbNormal"
              ) {
                newState = "Normal";
              } else {
                newState = "AbNormal";
              }
           
              updateinvistigations({
                id: [element.id],
                visit_id: props.state.id,
                patient_id: props.state.patient.id,
                test_id: [element.test.id],
                result_number: [newState],
                result_state: ["0"],
              });
            }}
          >
            <i class="fas fa-sync text-white"></i>
          </button>
        </td>
      </tr>
    );
  });
  return (
    <section>
      <div className="card">
        <div className="card-header bg-info">
          <h4 className="text-uppercase d-inline-block">Investigation </h4>
        </div>
        <div className="card-body">
          <MultiSelectComp state={props.state}></MultiSelectComp>

          <table className="table table-bordered mt-2">
            <thead className="bg-info">
              <tr>
                <th>Test Name</th>

                <th>Delete</th>
                <th>Result</th>
                <th>Toggle</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
          <div hidden>
            {" "}
            <FirstPrintPage ref={componentRef} state={props.state}
            type={"invistigation"}
            />
          </div>
          <div className=" text-center">
            <button
              className="btn btn-primary btn-lg mt-3 w-25"
              disabled={isComplete}
              onClick={(event) => {
                handlePrint();
              }}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestigaitonSection;
