import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Form as FormBot } from "react-bootstrap";
import { saveExamination, updateExamination } from "./examination_operation";
import { useSelector } from "react-redux";

const ExaminationSection = (props) => {
  let isComplete = props.state.state == "completed";

  const [state, setState] = useState({});
  let ex = props.state?.examination;

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type] = e.result;
      });

      setState({ ...temp });
    } else {
      setState({
        spo2: 0,
        HR: 0,
        Temperature: 0,
        bplSystolic: 0,
        bplDisatolic: 0,
        bprSystolic: 0,
        bprDisatolic: 0,
        tsh: 0,
        t3: 0,
        t4: 0,
        // milkType: "breast",
        rbs: 0,
        Hba1c: 0,
        // weightInBirthDay: 0,
        wTail: 0,
        hTail: 0,
        hcTail: 0,
        // durationAndMore: 0,
        height: 0,
        weight: 0,
        BMI: 0,
        other: "",
      });
    }
  }, []);

  const listTail = [
    "3",
    "3-5",
    "5",
    "5-10",
    "10",
    "10-25",
    "25",
    "25-50",
    "50",
    "50-75",
    "75",
    "75-90",
    "90-95",
    ">95",
  ];
  const typeFeeding = ["breast", "bottle", "both"];

  const calculateBMI = (height, wight) => {
    let heightSquared = ((height / 100) * height) / 100;
    let bmi = round(wight / heightSquared, 1);

    return bmi;
  };
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  const onSubmit = (e) => {
    e.preventDefault();

    let types = [];
    let result = [];
    Object.entries(state).forEach(([key, value]) => {
      if (
        value.toString() != "" &&
        value.toString() != undefined &&
        value.toString() != null
      ) {
        types.push(key.toString());
        result.push(value.toString());
      }
    });

    let ids = [];

    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      updateExamination(types, result, props.state.id, ids);
    } else {
      saveExamination(types, result, props.state.id);
    }
  };
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;

  let isAdmni = userInfo.user_info.user_type == "lab-admin";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <div className="card">
            <div className="card-header bg-info">
              <h4 className="text-uppercase d-inline-block">Examination </h4>
            </div>

            {/**/}

            {/**/}
            <div className="card-body m-auto">
              {isAdmni == false ? null : (
                <div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <div className="mb-2">
                        <label htmlFor="spo2">spo2</label>
                        <input
                          disabled={isComplete}
                          placeholder="spo2"
                          type="number"
                          step="any"
                          value={state.spo2}
                          onChange={(e) => {
                            setState({ ...state, spo2: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>

                      <label htmlFor="HR">HR</label>
                      <input
                        placeholder="HR"
                        disabled={isComplete}
                        type="number"
                        step="any"
                        value={state.HR}
                        onChange={(e) => {
                          setState({ ...state, HR: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Temperature">Temperature</label>
                      <input
                        placeholder="Temperature"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.Temperature}
                        onChange={(e) => {
                          setState({ ...state, Temperature: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="bplSystolic">bplSystolic</label>
                      <input
                        placeholder="bplSystolic"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.bplSystolic}
                        onChange={(e) => {
                          setState({ ...state, bplSystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />

                      <label htmlFor="bplDisatolic">bplDisatolic</label>
                      <input
                        disabled={isComplete}
                        placeholder="bplDisatolic"
                        type="number"
                        step="any"
                        value={state.bplDisatolic}
                        onChange={(e) => {
                          setState({ ...state, bplDisatolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="bprSystolic">bprSystolic</label>
                      <input
                        placeholder="bprSystolic"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.bprSystolic}
                        onChange={(e) => {
                          setState({ ...state, bprSystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                      <label htmlFor="bprDisatolic">bprDisatolic</label>
                      <input
                        disabled={isComplete}
                        placeholder="bprDisatolic"
                        type="number"
                        step="any"
                        value={state.bprDisatolic}
                        onChange={(e) => {
                          setState({ ...state, bprDisatolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="tsh">tsh</label>
                      <input
                        disabled={isComplete}
                        placeholder="tsh"
                        type="tsh"
                        value={state.tsh}
                        onChange={(e) => {
                          setState({ ...state, tsh: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="t3">t3</label>
                      <input
                        disabled={isComplete}
                        placeholder="t3"
                        type="number"
                        step="any"
                        value={state.t3}
                        onChange={(e) => {
                          setState({ ...state, t3: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="t4">t4</label>
                      <input
                        disabled={isComplete}
                        placeholder="t4"
                        type="number"
                        step="any"
                        value={state.t4}
                        onChange={(e) => {
                          setState({ ...state, t4: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="rbs">rbs</label>
                      <input
                        disabled={isComplete}
                        placeholder="rbs"
                        type="number"
                        step="any"
                        value={state.rbs}
                        onChange={(e) => {
                          setState({ ...state, rbs: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Hba1c">Hba1c</label>
                      <input
                        disabled={isComplete}
                        placeholder="Hba1c"
                        type="number"
                        step="any"
                        value={state.Hba1c}
                        onChange={(e) => {
                          setState({ ...state, Hba1c: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <hr />
                </div>
              )}

              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="height">height</label>
                  <input
                    disabled={isComplete}
                    placeholder="height"
                    type="number"
                    step="any"
                    value={state.height}
                    onChange={(e) => {
                      setState({
                        ...state,
                        height: e.target.value,
                        BMI: calculateBMI(e.target.value, state.weight).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="weight">weight</label>
                  <input
                    placeholder="weight"
                    type="number"
                    step="any"
                    disabled={isComplete}
                    value={state.weight}
                    onChange={(e) => {
                      setState({
                        ...state,
                        weight: e.target.value,
                        BMI: calculateBMI(state.height, e.target.value).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="BMI">BMI </label>
                  <input
                    placeholder="BMI"
                    type="number"
                    step="any"
                    disabled
                    value={state.BMI}
                    onChange={(e) => {
                      setState({ ...state, BMI: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>

              <hr />

              {isAdmni == false ? null : (
                <div>
                  {/* <div className="jumbotron pl-1 m-1 py-3">
                    <h4>Gynecology Obstetric</h4>
                    <hr />
                    <div className="row w-100">
                      <div className="form-group mr-2 ml-2 ">
                        <label htmlFor="rbs">LMP</label>

                        <input
                          type="date"
                          id="start"
                          name="trip-start"
                          className="form-control ml-1 mb-1"
                          onChange={(date) => {
                            
                            setState({ ...state, lmp: date.target.value });
                          }}
                          value={state.lmp}
                        />

                        <textarea
                          disabled={isComplete}
                          placeholder="LMPText"
                          value={state.LMPText}
                          onChange={(e) => {
                            setState({ ...state, LMPText: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                      <div className="form-group mr-2 ml-2 ">
                        <label htmlFor="Hba1c">EDD</label>
                        <input
                          type="date"
                          id="start"
                          name="trip-start"
                          className="form-control ml-1 mb-1"
                          onChange={(date) => {
                            
                            setState({ ...state, EDD: date.target.value });
                          }}
                          value={state.EDD}
                        />

                        <textarea
                          placeholder="eddText"
                          disabled={isComplete}
                          value={state.eddText}
                          onChange={(e) => {
                            setState({ ...state, eddText: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                    </div>
                    <textarea
                      placeholder="other"
                      disabled={isComplete}
                      value={state.otherGynecologyObstetric}
                      onChange={(e) => {
                        setState({
                          ...state,
                          otherGynecologyObstetric: e.target.value,
                        });
                      }}
                      className={`form-control shadow-none  ml-1 mr-1`}
                    />
                  </div>
                  <hr /> */}

                  {/* <div className="jumbotron pl-1 m-1 py-3">
                    <h4>Pediatric Examination</h4>
                    <hr />

                    <div className="row w-100">
                      <div className="form-group mr-2 ml-2">
                        <label htmlFor="weightInBirthDay">
                          weight on birthDay
                        </label>
                        <input
                          placeholder="weight on birthDay"
                          type="number"
                          step="any"
                          disabled={isComplete}
                          value={state.weightInBirthDay}
                          onChange={(e) => {
                            setState({
                              ...state,
                              weightInBirthDay: e.target.value,
                            });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                      <div className="form-group mr-2 ml-2">
                        <FormBot>
                          <FormBot.Group controlId="exampleForm.ControlSelect1">
                            <FormBot.Label>W tail</FormBot.Label>
                            <FormBot.Control
                              disabled={isComplete}
                              as="select"
                              value={state.wTail}
                              onChange={(e) => {
                                
                                setState({ ...state, wTail: e.target.value });
                              }}
                            >
                              {listTail.map((e) => (
                                <option key={e}>{e}</option>
                              ))}
                            </FormBot.Control>
                          </FormBot.Group>
                        </FormBot>
                      </div>
                      <div className="form-group mr-2 ml-2">
                        <FormBot>
                          <FormBot.Group controlId="exampleForm.ControlSelect1">
                            <FormBot.Label>H tail</FormBot.Label>
                            <FormBot.Control
                              disabled={isComplete}
                              as="select"
                              value={state.hTail}
                              onChange={(e) => {
                                
                                setState({ ...state, hTail: e.target.value });
                              }}
                            >
                              {listTail.map((e) => (
                                <option key={e}>{e}</option>
                              ))}
                            </FormBot.Control>
                          </FormBot.Group>
                        </FormBot>
                      </div>

                      <div className="form-group mr-2 ml-2">
                        <FormBot>
                          <FormBot.Group controlId="exampleForm.ControlSelect1">
                            <FormBot.Label>HC tail</FormBot.Label>
                            <FormBot.Control
                              as="select"
                              disabled={isComplete}
                              value={state.hcTail}
                              onChange={(e) => {
                                
                                setState({ ...state, hcTail: e.target.value });
                              }}
                            >
                              {listTail.map((e) => (
                                <option key={e}>{e}</option>
                              ))}
                            </FormBot.Control>
                          </FormBot.Group>
                        </FormBot>
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="form-group col-md-6">
                        <label className="form-check-label text-bold mb-2">
                          Milk type
                        </label>
                        <select
                          disabled={isComplete}
                          name="cars"
                          id="cars"
                          className="form-control"
                          onChange={(e) => {
                            
                            setState({ ...state, milkType: e.target.value });
                          }}
                          value={state.milkType}
                        >
                          {typeFeeding.map((e) => (
                            <option key={e}>{e}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-md-6 ">
                        <label htmlFor="durationAndMore">
                          Duration And More
                        </label>
                        <textarea
                          disabled={isComplete}
                          placeholder="duration and more"
                          value={state.durationAndMore}
                          onChange={(e) => {
                            setState({
                              ...state,
                              durationAndMore: e.target.value,
                            });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                    </div>
                  </div>
                  <hr /> */}
                  <label htmlFor="other">other</label>
                  <textarea
                    placeholder="other"
                    disabled={isComplete}
                    value={state.other}
                    onChange={(e) => {
                      setState({ ...state, other: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              )}
              {/*stop from here examination*/}

              <button
                className="btn btn-success w-100 mt-4"
                type="submit"
                disabled={isComplete}
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ExaminationSection;
