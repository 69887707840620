import React, { useEffect, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { getLabAction } from "../../../../actions/labAction";
import { useDispatch, useSelector } from "react-redux";
import { saveinvistigations } from "./investgation_operation";

const MultiSelectComp = (props) => {
  
  const dispatch = useDispatch();
  let isComplete = props.state.state=="completed";

  useEffect(() => {
    dispatch(getLabAction("userInfo.token"));
  }, []);
  const getLab = useSelector((state) => state.getLab);
  
  let data = [];
  getLab.getLabData?.forEach((element) => {
    data.push({ drug: element.name, id: element.id });
  });
  let dru = [];
  const onSelect = (selectedList, selectedItem) => {
    
    dru = selectedList;
  };
  const onRemove = (selectedList, selectedItem) => {
    
    dru = selectedList;
  };
  return (
    <div>
      <div className="d-flex justify-content-center">
        <Multiselect
          disable={isComplete}
         
          options={data}
          displayValue="drug"
          closeIcon="close"
          onSelect={onSelect}
          onRemove={onRemove}
        />
      </div>{" "}
      <div className="text-center">
        <button
          className="btn btn-success btn-lg mt-3 w-50"
          disabled={isComplete}
          onClick={(e) => {
            
            if (dru.length > 0) {
              let test_id = [];
              let results = [];
              let states = [];

              dru.forEach((e) => {

                test_id.push(e.id)
                results.push("Nil")
                states.push("5")

              });
              saveinvistigations(test_id,results,states , props.state.id);
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default MultiSelectComp;
