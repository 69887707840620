import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {createSystemReviews, upDateSystemReviews} from "./system_review";

const SystemRivew = (props) => {
    
    let isComplete = props.state.state == "completed";
    const [state, setState] = useState({});
    let ex = props.state?.systemReview;
    
    useEffect(() => {
        let temp = {};
        if (ex.length > 0) {
            ex?.forEach((e) => {
                temp[e.type] = e.note;
            });
            setState({...temp});
        } else {
            setState({
                GENERAL: "0",
                CVS: "0",
                RES: "0",
                GIT: "0",
                GUS: "0",
                NEU: "0",
                PSY: "0",
                DER: "0",
                ENDO: "0",
                note: "",
            });
        }
    }, []);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        

        
        if (props.state?.systemReview.length === 0) {
            
        } else {
            
        }
        
        let data = [];
        Object.entries(state).forEach(([key, value]) => {
            
            data.push({type: key, note: value});
        });
        let note = [];
        let types = [];
        data.forEach((e) => {
            types.push(e.type);
            note.push(e.note);
        });
        

        

        if (props.state?.systemReview?.length > 0) {
            
            let ids = [];

            props.state?.systemReview.forEach((er) => {
                ids.push(er.id);
            });
            upDateSystemReviews(note, types, ids, props.state.id);
        } else {
            

            createSystemReviews(note, types, props.state.id);
        }
    };

    return (
        <div className="card">
            <div className="card-header bg-info">
                <h4 className="text-uppercase d-inline-block">System Review</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    {/*//   –  –  –  –  -*/}
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   onChange={e => {
                                       
                                       setState({...state, GENERAL: e.target.checked == true ? "1" : "0"})
                                   }}
                                   checked={state.GENERAL == "1"}
                                   id="GENERAL"
                            />
                            <label className="form-check-label" htmlFor="GENERAL">
                                GENERAL
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   checked={state.CVS == "1"}
                                   onChange={e => {
                                       
                                       setState({...state, CVS: e.target.checked == true ? "1" : "0"})
                                   }}
                                   id="CVS"
                            />
                            <label className="form-check-label" htmlFor="CVS">
                                CVS
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   checked={state.RES == "1"}
                                   onChange={e => {
                                       
                                       setState({...state, RES: e.target.checked == true ? "1" : "0"})
                                   }}
                                   id="RES"
                            />
                            <label className="form-check-label" htmlFor="RES">
                                RES
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   checked={state.GIT == "1"}
                                   onChange={e => {
                                       
                                       setState({...state, GIT: e.target.checked == true ? "1" : "0"})
                                   }}
                                   id="GIT"
                            />
                            <label className="form-check-label" htmlFor="GIT">
                                GIT
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"

                                   checked={state.GUS == "1"}
                                   onChange={e => {
                                       
                                       setState({...state, GUS: e.target.checked == true ? "1" : "0"})
                                   }}
                                   id="GUS"
                            />
                            <label className="form-check-label" htmlFor="GUS">
                                GUS
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   checked={state.NEU == "1"}
                                   onChange={e => {
                                       
                                       setState({...state, NEU: e.target.checked == true ? "1" : "0"})
                                   }}
                                   id="NEU"
                            />
                            <label className="form-check-label" htmlFor="NEU">
                                NEU
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   checked={state.PSY == "1"} onChange={e => {
                                
                                setState({...state, PSY: e.target.checked == true ? "1" : "0"})
                            }}
                                   id="PSY"
                            />
                            <label className="form-check-label" htmlFor="PSY">
                                PSY
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   onChange={e => {
                                       
                                       setState({...state, DER: e.target.checked == true ? "1" : "0"})
                                   }}
                                   checked={state.DER == "1"}
                                   id="DER"
                            />
                            <label className="form-check-label" htmlFor="DER">
                                DER
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input disabled={isComplete}
                                   className="form-check-input"
                                   type="checkbox"
                                   checked={state.ENDO == "1"}
                                   onChange={e => {
                                       
                                       setState({...state, ENDO: e.target.checked == true ? "1" : "0"})
                                   }}
                                   id="ENDO"
                            />
                            <label className="form-check-label" htmlFor="ENDO">
                                ENDO
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="note">Note</label>
                    <textarea
                        disabled={isComplete}
                        placeholder="Note"
                        className="form-control"
                        value={state.note}
                        onChange={e => {
                            
                            setState({...state, note: e.target.value})
                        }}
                        id="note"
                        rows="3"
                    />
                    <button className="btn btn-success w-100 mt-4" onClick={handleSubmit} >
                        Sumbit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SystemRivew;
