import {Line} from 'react-chartjs-2';
import {useEffect, useState} from "react";

const PatientCharts = (props) => {
    const visits = props.state.visits;
    
    const [x, setXDate] = useState([])
    const [y, setYValue] = useState([])
    const [bmi, setBMIValue] = useState([])
    useEffect(() => {
        change("spo2")
        let tempX = [];
        let tempY = [];
        visits.forEach((e) => {
            tempX.push(e.follow_date.split(" ")[0])
            e.examination.forEach((d) => {
                if (d.type === "BMI") {

                    tempY.push(d.result)
                }
            })
            setXDate(tempX)
            setBMIValue(tempY)
        })
    }, [])


    const change = (pro) => {
        let tempY = [];
        visits.forEach((e) => {

            e.examination.forEach((d) => {
                if (d.type === pro) {

                    tempY.push(d.result)
                }
            })

            setYValue(tempY)
        })
    }

    const data = {
        labels: x,
        datasets: [
            {
                label: '',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: y,

            }
        ]
    };
    const BMIdata = {
        labels: x,
        datasets: [
            {
                label: '',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: bmi,
            }
        ]
    };
    const lineOptions = {
        scales: {
            xAxes: [{
                gridLines: {
                    display: false,
                },
            }],
            yAxes: [{
                // stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    beginAtZero: true,
                    // Return an empty string to draw the tick line but hide the tick label
                    // Return `null` or `undefined` to hide the tick line entirely
                    userCallback(value) {
                        // Convert the number to a string and splite the string every 3 charaters from the end
                        value = value.toString();
                        value = value.split(/(?=(?:...)*$)/);

                        // Convert the array to a string and format the output
                        value = value.join('.');
                        return `${value}`;
                    },
                },
            }],
        },
        legend: {
            display: false,
        },
        tooltips: {
            enabled: true,
        },

    };


    const styles = {
        fontFamily: 'sans-serif',
        textAlign: 'center',
    };


    return (<div style={styles} className="text-center">
        <h4>Examination</h4>
        <Line data={data} options={lineOptions}/>
        <button className="btn  bg-primary mx-1 m-1" onClick={e => { change("spo2")}}>SPO2</button>
        <button className="btn  m-1 bg-success mx-1"  onClick={e => { change("HR")}}>HR</button>
        <button className="btn  m-1 bg-warning mx-1 "  onClick={e => { change("Temperature")}}>Temperature</button>
        <button className="btn m-1 bg-danger mx-1"  onClick={e => { change("bplSystolic")}}>bpl Systolic</button>
        <button className="btn m-1 bg-primary mx-1"  onClick={e => { change("bplDisatolic")}}>bpl diastolic</button>
        <button className="btn m-1 bg-success mx-1" onClick={e => { change("bprSystolic")}}>bpr Systolic</button>
        <button className="btn m-1 bg-warning mx-1 "  onClick={e => { change("bprDisatolic")}}>bpr diastolic</button>
        <button className="btn m-1 bg-danger mx-1"    onClick={e => { change("t3")}}>t3</button>
        <button className="btn m-1  bg-red mx-1"    onClick={e => { change("t4")}}>t4</button>
        <button className="btn m-1 bg-gradient-cyan mx-1"    onClick={e => { change("rbs")}}>rbs</button>

        <hr/>
        <h4>BMI</h4>

        <Line data={BMIdata} options={lineOptions}/>
    </div>);
}

export default PatientCharts;