import React, { Component } from "react";
import "../printStyle.css";

class PageTrmenats extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let array = [];
    

    this.props.state?.forEach((element, index) => {
      
      array.push(
        <tr key={index}>
          <td  style={{ 'font-size':'25px' }}><div>{index + 1}</div></td>
          <td  style={{'font-size':'25px' }}><div>{element.drug.name}</div></td>
          <td  style={{ 'font-size':'25px' }}><div>{element.note}</div></td>
        </tr>
      );
    });

    return (
      <div className=" page" style={{'font-size':'22px'  ,'width':"16.2cm"}}>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-white p-0" style={{ width: "5%",'font-size':'25px' }}>
                    <div className="bg-success m-0 p-2">#</div>
                  </th>
                  <th className="text-white p-0" style={{ width: "45%",'font-size':'25px' }}>
                    <div className="bg-success m-0 p-2">Drug</div>
                  </th>
                  <th className="bg-success p-0" style={{ width: "50%" ,'font-size':'25px'}}>
                    <div className="bg-success m-0 p-2">Note</div>
                  </th>
                </tr>
              </thead>
              <tbody>{array}</tbody>
            </table>
          </div>

    );
  }
}

export default PageTrmenats;
